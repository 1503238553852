import { on_ready, is_elementor_edit, hover_intent } from './js/utils';

// load global styles
import './main.scss';

import 'components/forms/forms';
import 'components/footer/footer';
import 'components/header/header';
import 'components/carousel/carousel';
import 'components/accordions/accordions';
import 'components/toggle_height';
import 'components/footer/sticky_footer_cta';
import retina from './vendors/retina/retina';

on_ready(()=>{

    if( is_elementor_edit() ) return;
    dynamic_imports();
    
    init_hover_intent();
    init_retina();

    setTimeout(() => {
        g_flag_au();
    }, 50);

});

function dynamic_imports(){
    // slide menu
    if( document.querySelector('.ff_slide_menu') ) {
        import('components/slide_menu/slide_menu');
    }
    // search popup
    if( document.querySelector('.search_popup') ) {
        import('components/search_popup/search_popup');
    }
}

function init_hover_intent(){
    document.querySelectorAll('.hover_intent').forEach(el=>{
        hover_intent(el);
    });
}

function init_retina(){
    
    if( typeof retina === 'undefined' ) return;
    if( window.devicePixelRatio < 2) return;

    let items = document.querySelectorAll('.retina');
    if( !items ) return;
    
    items.forEach(item=>{
        if( item.nodeName == 'IMG' ) {
            item.dataset.rjs = 2;
        } else {
            // add to children
            item.querySelectorAll('img').forEach(img=>{
                img.dataset.rjs = 2;
            })
        }
    })
    
    retina();
}

function g_flag_au(){
    let flag = document.querySelector('.gt_option [title="English"] img');
    if( !flag ) return;
    flag.setAttribute('data-gt-lazy-src','/wp-content/themes/astraea/images/au-flag.webp');
    flag.setAttribute('src','/wp-content/themes/astraea/images/au-flag.webp');
}