export default class ScrollToTop {

    constructor( button, options = {} ){

        if( typeof button === 'string' ) {
            button = document.querySelector(button);
        }

        if( !button ) return;

        this.trigger_point = options.trigger_point ?? 2000;
        this.button = button;
        this.active = false;

        this.animation_duration = options.animation_duration ?? 500;
        
        this.animation_activate = options.animation_activate ?? 'fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both';
        this.animation_deactivate = options.animation_deactivate ?? 'fade-out-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both';

        this.animation_deactivate_duration = options.animation_deactivate_duration ?? 500;

        this.deactivate_timeout = null;

        this.init();
    }

    init(){

        window.addEventListener('scroll', ()=>{
            if( window.scrollY > this.trigger_point ) {
                this.activate();
            }
            else {
                this.deactivate();
            }
        });

        this.button.addEventListener('click', this.scroll_to_top)
    }

    activate(){
        if( this.active ) return;
        clearTimeout(this.deactivate_timeout);
        this.active = true;
        this.button.style.display = '';
        this.button.style.animation = this.animation_activate;
    }

    deactivate(){
        if( !this.active ) return;
        this.active = false;
        this.button.style.animation = this.animation_deactivate;

        this.deactivate_timeout = setTimeout(()=>{
            this.button.style.display = 'none';
        }, this.animation_deactivate_duration);
    }

    scroll_to_top(){
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

}